import React,{useEffect,useState} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Close from "@material-ui/icons/Close";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";

import Heatmap from "components/Charts/Heatmap.js";

import HeatMapProperty from "components/Charts/HeapMapProperty.js";

import HeatMapTenant from "components/Charts/HeapMapTenant.js";
import Autosuggest from 'react-autosuggest';
// import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import avatar from "assets/img/faces/marc.jpg";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';
import { Creators as TMActions } from 'store/ducks/tenantmonitoring.js';
import { useLocation } from "react-router-dom";


import PropTypes from 'prop-types';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";


import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import styles1 from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import {
  whiteColor,
  hexToRgb,
  megaColor,
  lyticsColor,
  infoColor
} from "assets/jss/material-dashboard-pro-react.js";
import Notificationsettings from "./Notificationsettings";

import SweetAlert from "react-bootstrap-sweetalert";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(styles,styles1);




const AssetManagerNotificationSettings = ({user,tmSaveNotificationSettingsRequest,tmNotificationSettingsByIdRequest,notificationSettingsById,tmEditNotificationSettingsRequest,editNotificationSettings}) => { 


  // ,tmEditNotificationSettingsRequest,editNotificationSettings

  const [userId, setUserId] = React.useState(user.id);
  const [input, setInput] = React.useState('');

  const history = useHistory();
  const location = useLocation();


const getSaveNotificationSettings = async (notificationSettings) => {
    tmSaveNotificationSettingsRequest(notificationSettings);
  
    };
   

    // type validation
const [required, setrequired] = React.useState("");
const [requiredState, setrequiredState] = React.useState("");
const [typeEmail, settypeEmail] = React.useState("");
const [typeEmailState, settypeEmailState] = React.useState("");
const [number, setnumber] = React.useState("");
const [numberState, setnumberState] = React.useState("");
const [url, seturl] = React.useState("");
const [urlState, seturlState] = React.useState("");
const [equalTo, setequalTo] = React.useState("");
const [whichEqualTo, setwhichEqualTo] = React.useState("");
const [equalToState, setequalToState] = React.useState("");

// range validation
const [minLength, setminLength] = React.useState("");
const [minLengthState, setminLengthState] = React.useState("");
const [maxLength, setmaxLength] = React.useState("");
const [maxLengthState, setmaxLengthState] = React.useState("");
const [range, setrange] = React.useState("");
const [rangeState, setrangeState] = React.useState("");
const [minValue, setminValue] = React.useState("");
const [minValueState, setminValueState] = React.useState("");
const [maxValue, setmaxValue] = React.useState("");
const [maxValueState, setmaxValueState] = React.useState("");

//const [dataList, setDataList] = React.useState(notificationSettingsById);

// const [propertyName,setPropertyName] =React.useState("");
// const [city,setCity] =React.useState("");
// const [address1,setAddress1] =React.useState("");
// const [state,setState] =React.useState("");
// const [fipsCounty,setFipsCounty] =React.useState("");
// const [msa,setMsa] =React.useState("");
// const [buildingClass,setBuildingClass] =React.useState("");
// const [noOfParkingSpaces,setNoOfParkingSpaces] =React.useState("");
// const [propertyOccupancy,setPropertyOccupancy] =React.useState("");
// const [marketVaccancy,setMarketVaccancy] =React.useState("");
// const [yearBuilt,setYearBuilt] =React.useState("");
// const [subMarketVaccancy,setSubMarketVaccancy] =React.useState("");


const [emailId,setEmailId]=React.useState("");
const [mobileNumber,setMobileNumber]=React.useState("");
const [propertyOverAllScoreEnabled,setPropertyOverAllScoreEnabled]=React.useState(true);
const [newsEnabled,setNewsEnabled]=React.useState(true);

const [derogatoryEnabled,setDerogatoryEnabled]=React.useState(true);
const [creditEnabled,setCreditEnabled]=React.useState(true);
const [pirsEnabled,setPirsEnabled]=React.useState(true);
const [tenantBankruptcyEnabled,setTenantBankruptcyEnabled]=React.useState(true);

const [competitiveLandscapeEnabled,setCompetitiveLandscapeEnabled] = React.useState(true);
const [financialEnabled,setFinancialEnabled] = React.useState(true);
const [financialBusinessPerformanceEnabled,setFinancialBusinessPerformanceEnabled]=React.useState(true);


const [bankruptcyRestructuringNewsEnabled,setBankruptcyRestructuringNewsEnabled]=React.useState(true);
const [mergerAndAcquisitionEnabled,setMergerAndAcquisitionEnabled]=React.useState(true);
const [regulatoryLegalEnabled,setRegulatoryLegalEnabled]=React.useState(true);
const [fundingActivityEnabled,setFundingActivityEnabled]=React.useState(true);
const [businessExpansionEnabled,setBusinessExpansionEnabled]=React.useState(true);
const [operationalChallengesEnabled,setOperationalChallengesEnabled]=React.useState(true);
const [partnershipsAndAlliancesEnabled,setPartnershipsAndAlliancesEnabled] = React.useState(true);
const [eventsEnabled,setEventsEnabled] = React.useState(true);
const [newOfferingsEnabled,setNewOfferingsEnabled] = React.useState(true);
const [managementChangesEnabled,setManagementChangesEnabled] = React.useState(true);
const [awardsAndRecognitionsEnabled,setAwardsAndRecognitionsEnabled] = React.useState(true);
const [procurementAndSalesEnabled,setProcurementAndSalesEnabled] = React.useState(true);
const [costCuttingEnabled,setCostCuttingEnabled] = React.useState(true);
const [businessClosureEnabled,setBusinessClosureEnabled] = React.useState(true);
const [auditRiskEnabled,setAuditRiskEnabled] = React.useState(true);
const [layOffsEnabled,setLayOffsEnabled] = React.useState(true);
const [roboticsEnabled,setRoboticsEnabled] = React.useState(true);
const [iotEnabled,setIotEnabled] = React.useState(true);
const [cyberSecurityEnabled,setCyberSecurityEnabled]=React.useState(true);
const [blockChainEnabled,setBlockChainEnabled]=React.useState(true);
const [artificialIntelligenceEnabled,setArtificialIntelligenceEnabled]=React.useState(true);
const [advancedManufacturingEnabled,setAdvancedManufacturingEnabled]=React.useState(true);
const [bigDataOrAnalyticsEnabled,setBigDataOrAnalyticsEnabled]=React.useState(true);
const [emergingTechEnabled,setEmergingTechEnabled] = React.useState(true);
const [divestitureEnabled,setDivestitureEnabled] = React.useState(true);
const [capitalInvestmentEnabled,setCapitalInvestmentEnabled] = React.useState(true);
const [corporateRestructuringEnabled,setCorporateRestructuringEnabled] = React.useState(true);
const [negativeNewsEnabled,setNegativeNewsEnabled] = React.useState(true);
const [hiringEnabled,setHiringEnabled]=React.useState(true);
const [financialResultsEnabled,setFinancialResultsEnabled]=React.useState(true);
const [defaultFinanceEnabled,setDefaultFinanceEnabled]=React.useState(true);
const [arOrVrEnabled,setArOrVrEnabled]=React.useState(true);
const [capitalRefinanceOrRestructuringEnabled,setCapitalRefinanceOrRestructuringEnabled]=React.useState(true);
const [demergerOrSpinOffEnabled,setDemergerOrSpinOffEnabled]=React.useState(true);
const [socialMediaEnabled,setSocialMediaEnabled]=React.useState(true);
const [allEnabled,setAllEnabled]=React.useState(true);

const [userType,setUserType]=React.useState(user.mainRole);
// const [firstName,setFirstName]=React.useState("");
// const [lastName,setLastName]=React.useState("");
const [firstName,setFirstName]=React.useState(user.firstName);
const [lastName,setLastName]=React.useState(user.lastName);
const [organizationId, setOrganizationId] = React.useState(JSON.parse(localStorage.getItem('@DeliveryApp:selectedOrgId')));
const [id,setId]=React.useState("");
const [dataStatus,setDataStatus]=React.useState(false);

// check Box
  const [checked, setChecked] = React.useState([true]);
  const [checkedTrackerScore, setCheckedTrackerScore] = React.useState([true]);
  const [checkedNews, setCheckedNews] = React.useState([true]);
  const [checkedDerogatory, setCheckedDerogatory] = React.useState([true]);
  const [checkedCredit, setCheckedCredit] = React.useState([true]);
  const [checkedPirs, setCheckedPirs] = React.useState([true]);
  const [checkedFinancialBusinessPerformance, setCheckedFinancialBusinessPerformance] = React.useState([true]);
  const [checkedBankruptcy, setCheckedBankruptcy] = React.useState([true]);
 
 
  const [checkedBankruptcyRestructuring, setCheckedBankruptcyRestructuring] = React.useState([true]);
  const [checkedMergersAcquisitionActivities, setCheckedMergersAcquisitionActivities] = React.useState([true]);
  const [checkedRegulatoryLegal, setCheckedRegulatoryLegal] = React.useState([true]);
  const [checkedFundingActivity, setCheckedFundingActivity] = React.useState([true]);
  const [checkedBusinessExpansion, setCheckedBusinessExpansion] = React.useState([true]);
  const [checkedOperationalChallenges, setCheckedOperationalChallenges] = React.useState([true]);
  const [checkedPartnershipsAndAlliances, setCheckedPartnershipsAndAlliances] = React.useState([true]);
  const [checkedEvents, setCheckedEvents] = React.useState([true]);
  const [checkedNewOfferings, setCheckedNewOfferings] = React.useState([true]);
  const [checkedManagementChanges, setCheckedManagementChanges] = React.useState([true]);
  const [checkedAwardsAndRecognitions, setCheckedAwardsAndRecognitions] = React.useState([true]);
  const [checkedProcurementAndSales, setCheckedProcurementAndSales] = React.useState([true]);
  const [checkedCostCutting, setCheckedCostCutting] = React.useState([true]);
  const [checkedBusinessClosure, setCheckedBusinessClosure] = React.useState([true]);
  const [checkedAuditRisk, setCheckedAuditRisk] = React.useState([true]);
  const [checkedLayOffs, setCheckedLayOffs] = React.useState([true]);
  const [checkedRobotics, setCheckedRobotics] = React.useState([true]);
  const [checkedIot, setCheckedIot] = React.useState([true]);
  const [checkedCyberSecurity, setCheckedCyberSecurity] = React.useState([true]);
  const [checkedBlockChain, setCheckedBlockChain] = React.useState([true]);
  const [checkedArtificialIntelligence, setCheckedArtificialIntelligence] = React.useState([true]);
  const [checkedAdvancedManufacturing, setCheckedAdvancedManufacturing] = React.useState([true]);
  const [checkedBigDataOrAnalytics, setCheckedBigDataOrAnalytics] = React.useState([true]);
  const [checkedEmergingTech, setCheckedEmergingTech] = React.useState([true]);
  const [checkedDivestiture, setCheckedDivestiture] = React.useState([true]);
  const [checkedCapitalInvestment, setCheckedCapitalInvestment] = React.useState([true]);
  const [checkedCorporateRestructuring, setCheckedCorporateRestructuring] = React.useState([true]);
  const [checkedNegativeNews, setCheckedNegativeNews] = React.useState([true]);
  const [checkedHiring, setCheckedHiring] = React.useState([true]);
  const [checkedFinancialResults, setCheckedFinancialResults] = React.useState([true]);
  const [checkedDefaultFinance, setCheckedDefaultFinance] = React.useState([true]);
  const [checkedArOrVr, setCheckedArOrVr] = React.useState([true]);
  const [checkedCapitalRefinanceOrRestructuringg, setCheckedCapitalRefinanceOrRestructuring] = React.useState([true]);
  const [checkedDemergerOrSpinOff, setCheckedDemergerOrSpinOff] = React.useState([true]);
  const [checkedSocialMedia,setCheckedSocialMedia] = React.useState([true]);
  const[checkedFinancialsEnabled,setCheckedFinancialsEnabled]=React.useState([true]);
  const [showButtonDisable,setShowButtonDisable]=React.useState(false);
  const[checkedAllEnabled,setCheckedAllEnabled]=React.useState([true]);
  const[emails,setEmails]=React.useState([]);
  const [notificationSettingsBasedOn, setNotificationSettingsBasedOn] = React.useState("General");

  const handleEmailChange = (e) => {
    let email = e.target.value.split(",")
    setEmails(email)
}
  const [alert1,setAlert1] = React.useState(false);

  function hideAlert1(event) {
    setAlert1(false);
    setShowButtonDisable(true);
    }

  const handleToggle = (event) => {
  
    if(event.target.name==='tracker_score'){
    setCheckedTrackerScore(event.target.checked);
    }
    else if(event.target.name==='news'){
      setCheckedNews(event.target.checked);
    }else if(event.target.name==='derog'){
      setCheckedDerogatory(event.target.checked);
    }else if(event.target.name==='credit'){
        setCheckedCredit(event.target.checked);
      }else if(event.target.name==='pirs'){
        setCheckedPirs(event.target.checked);
      }else if(event.target.name==='bankruptcy'){
        setCheckedBankruptcy(event.target.checked);
      }else if(event.target.name==='bankruptcy_restructuring'){
        setCheckedBankruptcyRestructuring(event.target.checked);
      }else if(event.target.name==='financial_business_performance'){
        setCheckedFinancialBusinessPerformance(event.target.checked);
      }else if(event.target.name==='regulatory_legal'){
        setCheckedRegulatoryLegal(event.target.checked);
      }else if(event.target.name==='funding_activity'){
        setCheckedFundingActivity(event.target.checked);
      }else if(event.target.name==='mergers_acquisition_activities'){
        setCheckedMergersAcquisitionActivities(event.target.checked);
      }else if(event.target.name==='business_expansion'){
        setCheckedBusinessExpansion(event.target.checked);
      }else if(event.target.name==='operational_challenges'){
        setCheckedOperationalChallenges(event.target.checked);
      }else if(event.target.name==='partnershipsAndAlliancesEnabled'){
      setCheckedPartnershipsAndAlliances(event.target.checked);
    }else if(event.target.name==='eventsEnabled'){
      setCheckedEvents(event.target.checked);
    }else if(event.target.name==='newOfferingsEnabled'){
      setCheckedNewOfferings(event.target.checked);
    }else if(event.target.name==='managementChangesEnabled'){
      setCheckedManagementChanges(event.target.checked);
    }else if(event.target.name==='awardsAndRecognitionsEnabled'){
      setCheckedAwardsAndRecognitions(event.target.checked);
    }else if(event.target.name==='procurementAndSalesEnabled'){
      setCheckedProcurementAndSales(event.target.checked);
    }else if(event.target.name==='costCuttingEnabled'){
      setCheckedCostCutting(event.target.checked);
    }
    else if(event.target.name==='businessClosureEnabled'){
      setCheckedBusinessClosure(event.target.checked);
    }
    else if(event.target.name==='audit_risk'){
      setCheckedAuditRisk(event.target.checked);
    }else if(event.target.name==='layoffs'){
      setCheckedLayOffs(event.target.checked);
    }else if(event.target.name==='robotics'){
      setCheckedRobotics(event.target.checked);
    }else if(event.target.name==='iot'){
      setCheckedIot(event.target.checked);
    }else if(event.target.name==='cyber_security'){
      setCheckedCyberSecurity(event.target.checked);
    }else if(event.target.name==='block_chain'){
      setCheckedBlockChain(event.target.checked);
    }else if(event.target.name==='artificial_intelligence'){
      setCheckedArtificialIntelligence(event.target.checked);
    }else if(event.target.name==='advanced_manufacturing'){
      setCheckedAdvancedManufacturing(event.target.checked);
    }else if(event.target.name==='big_data_or_analytics'){
      setCheckedBigDataOrAnalytics(event.target.checked);
    }else if(event.target.name==='emerging_tech'){
      setCheckedEmergingTech(event.target.checked);
    }else if(event.target.name==='divestiture'){
      setCheckedDivestiture(event.target.checked);
    }else if(event.target.name==='capital_investment'){
      setCheckedCapitalInvestment(event.target.checked);
    }else if(event.target.name==='corporate_restructuring'){
      setCheckedCorporateRestructuring(event.target.checked);
    }else if(event.target.name==='negative_news'){
      setCheckedNegativeNews(event.target.checked);
    }else if(event.target.name==='hiring'){
      setCheckedHiring(event.target.checked);
    }else if(event.target.name==='financial_results'){
      setCheckedFinancialResults(event.target.checked);
    }else if(event.target.name==='default_finance'){
      setCheckedDefaultFinance(event.target.checked);
    }else if(event.target.name==='ar_or_vr'){
      setCheckedArOrVr(event.target.checked);
    }else if(event.target.name==='capital_refinance_or_restructuring'){
      setCheckedCapitalRefinanceOrRestructuring(event.target.checked);
    }else if(event.target.name==='demerger_or_spinoff'){
      setCheckedDemergerOrSpinOff(event.target.checked);
    } else if(event.target.name==='financials'){
      setCheckedFinancialsEnabled(event.target.checked);
    } else if(event.target.name==='allEnabled'){
      setCheckedAllEnabled(event.target.checked);
    }
    
    
      else{

      }
    
  }; 



  //angel
  // const [unchecked, setUnChecked] = React.useState([24, 22]);
  const handleChange = (event) => {

    //alert(event.target.name);
    setChecked(event.target.checked);
  
  return event.target.checked
  };
  useEffect(() => {
   
    setAllEnabled(false);
    setEmailId("");
    setMobileNumber("");
    setPropertyOverAllScoreEnabled("");
    setNewsEnabled("");
    setMergerAndAcquisitionEnabled("");
    setDerogatoryEnabled("");
    setCreditEnabled("");
    setPirsEnabled("");
    setTenantBankruptcyEnabled("");
    setFinancialBusinessPerformanceEnabled("");
    setBusinessExpansionEnabled("");
    setRegulatoryLegalEnabled("");
    setFundingActivityEnabled("");
    setOperationalChallengesEnabled("");


 setFinancialBusinessPerformanceEnabled("");

  setCompetitiveLandscapeEnabled("");

  setFinancialEnabled("");

  setBankruptcyRestructuringNewsEnabled("");
  setMergerAndAcquisitionEnabled("");
  setRegulatoryLegalEnabled("");
  setFundingActivityEnabled("");
  setBusinessExpansionEnabled("");
  setOperationalChallengesEnabled("");
  setPartnershipsAndAlliancesEnabled("");
  setEventsEnabled("");
  setNewOfferingsEnabled("");
  setManagementChangesEnabled("");
  setAwardsAndRecognitionsEnabled("");
  setProcurementAndSalesEnabled("");
  setCostCuttingEnabled("");
  setBusinessClosureEnabled("");
  setAuditRiskEnabled("");
  setLayOffsEnabled("");
  setRoboticsEnabled("");
  setIotEnabled("");
  setCyberSecurityEnabled("");
  setBlockChainEnabled("");
  setArtificialIntelligenceEnabled("");
  setAdvancedManufacturingEnabled("");
  setBigDataOrAnalyticsEnabled("");
  setEmergingTechEnabled("");
  setDivestitureEnabled("");
  setCapitalInvestmentEnabled("");
  setCorporateRestructuringEnabled("");
  setNegativeNewsEnabled("");
  setHiringEnabled("");
  setFinancialResultsEnabled("");
  setDefaultFinanceEnabled("");
  setArOrVrEnabled("");
  setCapitalRefinanceOrRestructuringEnabled("");
  setDemergerOrSpinOffEnabled("");
  setSocialMediaEnabled("");

    getNotificationSettingsById(userId);
      // alert("useEffect"+JSON.stringify(notificationSettingsById));


      // setCheckedBankruptcyRestructuring(notificationSettingsById.bankruptcyRestructuringNewsEnabled);
      // setCheckedMergersAcquisitionActivities(notificationSettingsById.mergerAndAcquisitionEnabled);
      // setCheckedRegulatoryLegal(notificationSettingsById.regulatoryLegalEnabled);
      // setCheckedFundingActivity(notificationSettingsById.fundingActivityEnabled);
      // setCheckedBusinessExpansion(notificationSettingsById.businessExpansionEnabled);
      // setCheckedOperationalChallenges(notificationSettingsById.operationalChallengesEnabled);
      // setCheckedPartnershipsAndAlliances(notificationSettingsById.partnershipsAndAlliancesEnabled);
      // setCheckedEvents(notificationSettingsById.eventsEnabled);
      // setCheckedNewOfferings(notificationSettingsById.newOfferingsEnabled);
      // setCheckedManagementChanges(notificationSettingsById.managementChangesEnabled);
      // setCheckedAwardsAndRecognitions(notificationSettingsById.awardsAndRecognitionsEnabled);
      // setCheckedProcurementAndSales(notificationSettingsById.procurementAndSalesEnabled);
      // setCostCuttingEnabled(notificationSettingsById.costCuttingEnabled);
      // setCheckedBusinessClosure(notificationSettingsById.businessClosureEnabled);
      // setCheckedAuditRisk(notificationSettingsById.auditRiskEnabled);
      // setCheckedLayOffs(notificationSettingsById.layOffsEnabled);
      // setCheckedRobotics(notificationSettingsById.roboticsEnabled);
      // setCheckedIot(notificationSettingsById.iotEnabled);
      // setCheckedCyberSecurity(notificationSettingsById.cyberSecurityEnabled);
      // setCheckedBlockChain(notificationSettingsById.blockChainEnabled);
      // setCheckedArtificialIntelligence(notificationSettingsById.artificialIntelligenceEnabled);
      // setCheckedAdvancedManufacturing(notificationSettingsById.advancedManufacturingEnabled);
      // setCheckedBigDataOrAnalytics(notificationSettingsById.bigDataOrAnalyticsEnabled);
      // setCheckedEmergingTech(notificationSettingsById.emergingTechEnabled);
      // setCheckedDivestiture(notificationSettingsById.divestitureEnabled);
      // setCheckedCapitalInvestment(notificationSettingsById.capitalInvestmentEnabled);
      // setCheckedCorporateRestructuring(notificationSettingsById.corporateRestructuringEnabled);
      // setCheckedNegativeNews(notificationSettingsById.negativeNewsEnabled);
      // setCheckedHiring(notificationSettingsById.hiringEnabled);
      // setCheckedFinancialResults(notificationSettingsById.financialResultsEnabled);
      // setCheckedDefaultFinance(notificationSettingsById.defaultFinanceEnabled);
      // setCheckedArOrVr(notificationSettingsById.arOrVrEnabled);
      // setCheckedCapitalRefinanceOrRestructuring(notificationSettingsById.capitalRefinanceOrRestructuringEnabled);
      // setCheckedDemergerOrSpinOff(notificationSettingsById.demergerOrSpinOffEnabled);
      // setCheckedFinancialsEnabled(notificationSettingsById.financialEnabled);
  
  



      setEmailId(notificationSettingsById.emailId);
      setMobileNumber(notificationSettingsById.mobileNumber);
      setId(notificationSettingsById.id);
      // setUserId(notificationSettingsById.userId);
      // setUserType(notificationSettingsById.userType);
      // setFirstName(notificationSettingsById.firstName);
      // setLastName(notificationSettingsById.lastName);
      // setOrganizationId(notificationSettingsById.organizationId);

      setPropertyOverAllScoreEnabled(notificationSettingsById.propertyOverAllScoreEnabled);
      setNewsEnabled(notificationSettingsById.newsEnabled);

      setDerogatoryEnabled(notificationSettingsById.derogatoryEnabled);
      setCreditEnabled(notificationSettingsById.creditEnabled);
      setPirsEnabled(notificationSettingsById.pirsEnabled);
      setTenantBankruptcyEnabled(notificationSettingsById.tenantBankruptcyEnabled);
      setFinancialBusinessPerformanceEnabled(notificationSettingsById.financialBusinessPerformanceEnabled);
      setCompetitiveLandscapeEnabled(notificationSettingsById.competitiveLandscapeEnabled);
      setFinancialEnabled(notificationSettingsById.financialsEnabled);
   


    
      setBankruptcyRestructuringNewsEnabled(notificationSettingsById.bankruptcyRestructuringNewsEnabled);
      setMergerAndAcquisitionEnabled(notificationSettingsById.mergerAndAcquisitionEnabled);
      setRegulatoryLegalEnabled(notificationSettingsById.regulatoryLegalEnabled);
      setFundingActivityEnabled(notificationSettingsById.fundingActivityEnabled);
      setBusinessExpansionEnabled(notificationSettingsById.businessExpansionEnabled);
      setOperationalChallengesEnabled(notificationSettingsById.operationalChallengesEnabled);
      setPartnershipsAndAlliancesEnabled(notificationSettingsById.partnershipsAndAlliancesEnabled);
      setEventsEnabled(notificationSettingsById.eventsEnabled);
      setNewOfferingsEnabled(notificationSettingsById.newOfferingsEnabled);
      setManagementChangesEnabled(notificationSettingsById.managementChangesEnabled);
      setAwardsAndRecognitionsEnabled(notificationSettingsById.awardsAndRecognitionsEnabled);
      setProcurementAndSalesEnabled(notificationSettingsById.procurementAndSalesEnabled);
      setCostCuttingEnabled(notificationSettingsById.costCuttingEnabled);
      setBusinessClosureEnabled(notificationSettingsById.businessClosureEnabled);
      setAuditRiskEnabled(notificationSettingsById.auditRiskEnabled);
      setLayOffsEnabled(notificationSettingsById.layOffsEnabled);
      setRoboticsEnabled(notificationSettingsById.roboticsEnabled);
      setIotEnabled(notificationSettingsById.iotEnabled);
      setCyberSecurityEnabled(notificationSettingsById.cyberSecurityEnabled);
      setBlockChainEnabled(notificationSettingsById.blockChainEnabled);
      setArtificialIntelligenceEnabled(notificationSettingsById.artificialIntelligenceEnabled);
      setAdvancedManufacturingEnabled(notificationSettingsById.advancedManufacturingEnabled);
      setBigDataOrAnalyticsEnabled(notificationSettingsById.bigDataOrAnalyticsEnabled);
      setEmergingTechEnabled(notificationSettingsById.emergingTechEnabled);
      setDivestitureEnabled(notificationSettingsById.divestitureEnabled);
      setCapitalInvestmentEnabled(notificationSettingsById.capitalInvestmentEnabled);
      setCorporateRestructuringEnabled(notificationSettingsById.corporateRestructuringEnabled);
      setNegativeNewsEnabled(notificationSettingsById.negativeNewsEnabled);
      setHiringEnabled(notificationSettingsById.hiringEnabled);
      setFinancialResultsEnabled(notificationSettingsById.financialResultsEnabled);
      setDefaultFinanceEnabled(notificationSettingsById.defaultFinanceEnabled);
      setArOrVrEnabled(notificationSettingsById.arOrVrEnabled);
      setCapitalRefinanceOrRestructuringEnabled(notificationSettingsById.capitalRefinanceOrRestructuringEnabled);
      setDemergerOrSpinOffEnabled(notificationSettingsById.demergerOrSpinOffEnabled);
      setSocialMediaEnabled(notificationSettingsById.socialMediaEnabled);
      setNotificationSettingsBasedOn(notificationSettingsById.notificationSettingsBasedOn);
      // setFinancialEnabled(notificationSettingsById.financialEnabled);
  //  console.log("emails"+JSON.stringify(emailId));
      
      setTimeout(() => {
        setDataStatus(true);
      }, 3000);
  
    },[dataStatus,notificationSettingsById!==""]);

    const handleChange1 = (event) => {
      if (event.target.value === "General") {
       
        setNotificationSettingsBasedOn(event.target.value)
        
      }
      else if (event.target.value == "Tenant Specific") {
       
        setNotificationSettingsBasedOn(event.target.value)
       
      }
  };
 
  const getNotificationSettingsById = async (userId) => {
   await tmNotificationSettingsByIdRequest(userId);
// alert("function"+JSON.stringify(notificationSettingsById));
    };
//alert(JSON.stringify(user));
const getEditNotificationSettings = async (notificationSettings) => {
  tmEditNotificationSettingsRequest(notificationSettings);
 
  };
  


  const submitForm = () => {
  
    const notificationSettings={

      id:id,
      emailId:emailId,
      mobileNumber:mobileNumber,
      propertyOverAllScoreEnabled:propertyOverAllScoreEnabled,
      newsEnabled:newsEnabled,
      derogatoryEnabled:derogatoryEnabled,
      creditEnabled:creditEnabled,
      pirsEnabled:pirsEnabled,
      tenantBankruptcyEnabled:tenantBankruptcyEnabled,
      financialBusinessPerformanceEnabled:financialBusinessPerformanceEnabled,
      competitiveLandscapeEnabled:competitiveLandscapeEnabled,
        
      financialsEnabled:financialEnabled,

      bankruptcyRestructuringNewsEnabled:bankruptcyRestructuringNewsEnabled,
      mergerAndAcquisitionEnabled:mergerAndAcquisitionEnabled,
      regulatoryLegalEnabled:regulatoryLegalEnabled,
      fundingActivityEnabled:fundingActivityEnabled,
      businessExpansionEnabled:businessExpansionEnabled,
      operationalChallengesEnabled:operationalChallengesEnabled,
      partnershipsAndAlliancesEnabled:partnershipsAndAlliancesEnabled,
      eventsEnabled:eventsEnabled,
      newOfferingsEnabled:newOfferingsEnabled,
      managementChangesEnabled:managementChangesEnabled,
      awardsAndRecognitionsEnabled:awardsAndRecognitionsEnabled,
      procurementAndSalesEnabled:procurementAndSalesEnabled,
      costCuttingEnabled:costCuttingEnabled,
      businessClosureEnabled:businessClosureEnabled,
      auditRiskEnabled:auditRiskEnabled,
      layOffsEnabled:layOffsEnabled,
      roboticsEnabled:roboticsEnabled,
      iotEnabled:iotEnabled,
      cyberSecurityEnabled:cyberSecurityEnabled,
      blockChainEnabled:blockChainEnabled,
      artificialIntelligenceEnabled:artificialIntelligenceEnabled,
      advancedManufacturingEnabled:advancedManufacturingEnabled,
      bigDataOrAnalyticsEnabled:bigDataOrAnalyticsEnabled,
      emergingTechEnabled:emergingTechEnabled,
      divestitureEnabled:divestitureEnabled,
      capitalInvestmentEnabled:capitalInvestmentEnabled,
      corporateRestructuringEnabled:corporateRestructuringEnabled,
      negativeNewsEnabled:negativeNewsEnabled,
      hiringEnabled:hiringEnabled,
      financialResultsEnabled:financialResultsEnabled,
      defaultFinanceEnabled:defaultFinanceEnabled,
      arOrVrEnabled:arOrVrEnabled,
      capitalRefinanceOrRestructuringEnabled:capitalRefinanceOrRestructuringEnabled,
      demergerOrSpinOffEnabled:demergerOrSpinOffEnabled,
      socialMediaEnabled:socialMediaEnabled,
      notificationSettingsBasedOn:notificationSettingsBasedOn,
         

      userId:userId,
      userType:userType,
      firstName:firstName,
      lastName:lastName,
      organizationId:organizationId,
  };
 
 
 if(notificationSettings.id=='null'){
  tmSaveNotificationSettingsRequest(notificationSettings);
  history.push( setAlert1(true),{ 
     });
 }
 
else{
 tmEditNotificationSettingsRequest(notificationSettings);
 history.push( setAlert1(true),{ 
   });
 
}
// alert(JSON.stringify(notificationSettings));


  
//  alert(JSON.stringify(notificationSettings));
    // history.push({ 
    //   pathname: '/admin/propertylist',
    //   state:{ message:"Property Saved Successfully" }
    //  });
  };


  // function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};

  const [value, setValue] = React.useState(null);

  const classes = useStyles(); 
  if(dataStatus){
  return (
    
    <div>
      <form>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
          <CardHeader style={{ background: megaColor,color:"white",fontSize:"17px" }} icon>
            <CardIcon color="info">
                <PermIdentity />
              </CardIcon>
              <h4><b>
              Notification Settings
           </b>
              </h4> 
            </CardHeader>
            <CardBody>
<br></br>

           

        
            <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Email Id"
                    id="company-disabled"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                     labelText="Email Alert Recipients"
                     id="emailId"
                     value={emailId}
                     success={requiredState === "success"}
                     error={requiredState === "error"}
                     formControlProps={{
                       fullWidth: true
                     }}
                     inputProps={{
                       onChange: event => {
                         if (verifyLength(event.target.value, 1)) {
                           setEmailId(event.target.value);
                         } else {
                          setEmailId("");
                           setrequiredState("error");
                         }
                         setrequired(event.target.value);
                        
                       },
                    
                       type: "text",
                       endAdornment:
                         requiredState === "error" ? (
                           <InputAdornment position="end">
                             <Close className={classes.danger} />
                           </InputAdornment>
                         ) : (
                           undefined
                         )
                     }}
                  />
                </GridItem>
                
                
              </GridContainer>

              
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    labelText="Mobile"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
              
              
              
                <GridItem xs={12} sm={12} md={9}>
                  <CustomInput
                    labelText="Example +15243567896,+14352345678"
                    id="mobileNumber"
                    value={mobileNumber}
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setMobileNumber(event.target.value);
                        } else {
                          setMobileNumber("");
                          setrequiredState("error");
                          setMobileNumber("");
                       }
                       setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}
                  />
              

                 
                </GridItem>
                
                {/* <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Mobile"
                    
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                <CustomInput
                    labelText="Example +15243567896,+14352345678"
                    id="mobileNumber"
                    value={mobileNumber}
                    success={requiredState === "success"}
                    error={requiredState === "error"}
                    formControlProps={{
                      fullWidth: true
                    }}

                    inputProps={{
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setMobileNumber(event.target.value);
                        } else {
                          setrequiredState("error");
                       }
                       setrequired(event.target.value);
                      },
                      type: "text",
                      endAdornment:
                        requiredState === "error" ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                    }}

                  />
                 
                </GridItem> */}
                
              </GridContainer>
              
             
              
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Enable Notification for:"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
            
    
                <GridItem xs={6} sm={6} md={2}>
                <br></br>
                <FormControlLabel
               
          control={
            
            <Checkbox
            value={propertyOverAllScoreEnabled}
              tabIndex={-1}
              
              onClick={handleToggle}
              checked={propertyOverAllScoreEnabled}
             
              defaultChecked={classes.uncheckedIcon}
            //  onChange={handleToggle}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
               //unchecked: classes.unchecked,
                root: classes.checkRoot
              }}
              //angel
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setPropertyOverAllScoreEnabled(true);
                  } else {
                    setPropertyOverAllScoreEnabled(false);
                  }
                  
                },
                
              }}
       
            />
          }
          classes={{ label: classes.label }}
          label="Tracker Score"
          name="tracker_score"
        />
                </GridItem>


                <GridItem xs={6} sm={6} md={1}>
                <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={newsEnabled}
              tabIndex={-1}
              onClick={handleToggle}
              checked={newsEnabled}
            
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setNewsEnabled(true);
                  } else {
                    setNewsEnabled(false);
                  }
                  
                },
                
              }}
       
              
            />
          }
         
          classes={{ label: classes.label }}
          label="News"
          name="news"
        />
        
        </GridItem>
     

<GridItem xs={6} sm={6} md={1}>
  <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={derogatoryEnabled}
              tabIndex={-1}
               onClick={handleToggle}
              checked={derogatoryEnabled}
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setDerogatoryEnabled(true);
                  } else {
                    setDerogatoryEnabled(false);
                  }
                  
                },
                
              }}
              
            />
          }
          classes={{ label: classes.label }}
          label="Derog"
          name="derog"
        />
              
                </GridItem>
                
                <br></br>
                
                <GridItem xs={6} sm={6} md={1}>
                  <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={creditEnabled}
              tabIndex={-1}
              onClick={handleToggle}
              checked={creditEnabled}
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setCreditEnabled(true);
                  } else {
                    setCreditEnabled(false);
                  }
                  
                },
                
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Credit"
          name="credit"
        />
              
                </GridItem>
                <GridItem xs={6} sm={6} md={1}>
                  <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={pirsEnabled}
              tabIndex={-1}
              onClick={handleToggle}
              checked={pirsEnabled}
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setPirsEnabled(true);
                  } else {
                    setPirsEnabled(false);
                  }
                  
                },
                
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Industry Risk Score"
          name="pirs"
        />
              
                </GridItem>
                <GridItem xs={6} sm={6} md={2}>
               <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={tenantBankruptcyEnabled}
              tabIndex={-1}
              onClick={handleToggle}
              checked={tenantBankruptcyEnabled}
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setTenantBankruptcyEnabled(true);
                  } else {
                    setTenantBankruptcyEnabled(false);
                  }
                  
                },
                
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Bankruptcy"
          name="bankruptcy"
        />
            </GridItem>
                    {/* <GridItem xs={6} sm={6} md={1}>
                  <br></br>
                <FormControlLabel
          control={
            <Checkbox
            value={socialMediaEnabled}
              tabIndex={-1}
              onClick={handleToggle}
              checked={socialMediaEnabled}
              defaultChecked={classes.uncheckedIcon}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setSocialMediaEnabled(true);
                  } else {
                    setSocialMediaEnabled(false);
                  }
                  
                },
                
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Social Media"
          name=""
        />
                </GridItem> */}
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    labelText="Notification Based on"
                    id="mobile"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true
                    }}
                  />
                </GridItem>
              
              
              
                <GridItem xs={12} sm={12} md={2} style={{marginTop:"30px"}}>
              
 <RadioGroup
 aria-label="general"
 name="general"
 value={notificationSettingsBasedOn}
 onChange={handleChange1}

 >
 <FormControlLabel
 
 value="General"
 control={<Radio style={{ color: "#00628b", transform: 'scale(0.8)' }} />}
 label={<text style={{ color: "grey", fontSize: "12px" }}>General</text>}
 />
 </RadioGroup>

 </GridItem>

 <GridItem xs={12} sm={12} md={2} style={{marginTop:"30px"}}>
 <RadioGroup
 aria-label="tenantSpecific"
 name="tenantSpecific"
 value={notificationSettingsBasedOn}
 onChange={handleChange1}
 >
 <FormControlLabel
 value="Tenant Specific"
 control={<Radio style={{ color: "#00628b", transform: 'scale(0.8)' }}/>}
 label={<text style={{ color: "grey", fontSize: "12px" }}>Tenant Specific</text>}
 />
 </RadioGroup>


              
                </GridItem>
                
              </GridContainer>
             

         <GridItem xs={12} sm={12} md={12}>
         {/* <h4><strong>News Category</strong></h4> */}
              <CustomInput 
                  
                  labelText={<text style={{color:"black",fontSize:"18px"}}>News Category</text>}
                  id="company-disabled"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true
                  }}
                  />
                  </GridItem>
                 <br></br>
              {/* <GridContainer>
             <GridItem xs={12} sm={12} md={3}>
               <h4>Source</h4>
                </GridItem> 

                 <GridItem xs={12} sm={12} md={4}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Social Media"
        />
        </GridItem>

<GridItem xs={12} sm={12} md={4}>
                <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              // onClick={() => handleToggle(22)}
              // checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="News"
        />
              
                </GridItem> 
                

                 </GridContainer>  */}
                 <br></br>
                 <GridContainer>
                 <GridItem xs={12} sm={12} md={2}>
                 <h5><strong>Categories</strong></h5> 
                 </GridItem>
                 <GridItem xs={12} sm={12} md={6} >
                <FormControlLabel
               
          control={
            
            <Checkbox
            value={allEnabled}
              tabIndex={-1}
              
              onClick={handleToggle}
              checked={allEnabled}
             
              defaultChecked={classes.uncheckedIcon}
            //  onChange={handleToggle}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
               //unchecked: classes.unchecked,
                root: classes.checkRoot
              }}
              //angel
              inputProps={{
                onChange: event => {
                  if (event.target.checked) {
                    setAllEnabled(true);
                    setCostCuttingEnabled(true);
                    setMergerAndAcquisitionEnabled(true);
                    setFinancialBusinessPerformanceEnabled(true);
                    setBusinessExpansionEnabled(true);
                    setRegulatoryLegalEnabled(true);
                    setFundingActivityEnabled(true);
                    setOperationalChallengesEnabled(true);
                    setFinancialBusinessPerformanceEnabled(true);
                    setCompetitiveLandscapeEnabled(true);
                    setFinancialEnabled(true);
                    setBankruptcyRestructuringNewsEnabled(true);
                    setMergerAndAcquisitionEnabled(true);
                    setRegulatoryLegalEnabled(true);
                    setFundingActivityEnabled(true);
                    setBusinessExpansionEnabled(true);
                    setOperationalChallengesEnabled(true);
                    setPartnershipsAndAlliancesEnabled(true);
                    setEventsEnabled(true);
                    setNewOfferingsEnabled(true);
                    setManagementChangesEnabled(true);
                    setAwardsAndRecognitionsEnabled(true);
                    setProcurementAndSalesEnabled(true);
                    setCostCuttingEnabled(true);
                    setBusinessClosureEnabled(true);
                    setAuditRiskEnabled(true);
                    setLayOffsEnabled(true);
                    setRoboticsEnabled(true);
                    setIotEnabled(true);
                    setCyberSecurityEnabled(true);
                    setBlockChainEnabled(true);
                    setArtificialIntelligenceEnabled(true);
                    setAdvancedManufacturingEnabled(true);
                    setBigDataOrAnalyticsEnabled(true);
                    setEmergingTechEnabled(true);
                    setDivestitureEnabled(true);
                    setCapitalInvestmentEnabled(true);
                    setCorporateRestructuringEnabled(true);
                    setNegativeNewsEnabled(true);
                    setHiringEnabled(true);
                    setFinancialResultsEnabled(true);
                    setDefaultFinanceEnabled(true);
                    setArOrVrEnabled(true);
                    setCapitalRefinanceOrRestructuringEnabled(true);
                    setDemergerOrSpinOffEnabled(true);
                  } else {
                    setAllEnabled(false);
                    setCostCuttingEnabled(false);
                    setMergerAndAcquisitionEnabled(false);
                    setFinancialBusinessPerformanceEnabled(false);
                    setBusinessExpansionEnabled(false);
                    setRegulatoryLegalEnabled(false);
                    setFundingActivityEnabled(false);
                    setOperationalChallengesEnabled(false);
                    setFinancialBusinessPerformanceEnabled(false);
                    setCompetitiveLandscapeEnabled(false);
                    setFinancialEnabled(false);
                    setBankruptcyRestructuringNewsEnabled(false);
                    setMergerAndAcquisitionEnabled(false);
                    setRegulatoryLegalEnabled(false);
                    setFundingActivityEnabled(false);
                    setBusinessExpansionEnabled(false);
                    setOperationalChallengesEnabled(false);
                    setPartnershipsAndAlliancesEnabled(false);
                    setEventsEnabled(false);
                    setNewOfferingsEnabled(false);
                    setManagementChangesEnabled(false);
                    setAwardsAndRecognitionsEnabled(false);
                    setProcurementAndSalesEnabled(false);
                    setCostCuttingEnabled(false);
                    setBusinessClosureEnabled(false);
                    setAuditRiskEnabled(false);
                    setLayOffsEnabled(false);
                    setRoboticsEnabled(false);
                    setIotEnabled(false);
                    setCyberSecurityEnabled(false);
                    setBlockChainEnabled(false);
                    setArtificialIntelligenceEnabled(false);
                    setAdvancedManufacturingEnabled(false);
                    setBigDataOrAnalyticsEnabled(false);
                    setEmergingTechEnabled(false);
                    setDivestitureEnabled(false);
                    setCapitalInvestmentEnabled(false);
                    setCorporateRestructuringEnabled(false);
                    setNegativeNewsEnabled(false);
                    setHiringEnabled(false);
                    setFinancialResultsEnabled(false);
                    setDefaultFinanceEnabled(false);
                    setArOrVrEnabled(false);
                    setCapitalRefinanceOrRestructuringEnabled(false);
                    setDemergerOrSpinOffEnabled(false);
                  }
                  
                },
                
              }}
       
            />
          }
          classes={{ label: classes.label }}
          // labelPlacement="start"
          label="Select All"
          name="allEnabled"
        />
                </GridItem>
                </GridContainer>
<GridContainer>
  <GridItem  xs={12} sm={12} md={3}>
                <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={bankruptcyRestructuringNewsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={bankruptcyRestructuringNewsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setBankruptcyRestructuringNewsEnabled(true);
                          } else {
                            setBankruptcyRestructuringNewsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Bankruptcy & Restructuring"
                  name="bankruptcy_restructuring"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={mergerAndAcquisitionEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={mergerAndAcquisitionEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setMergerAndAcquisitionEnabled(true);
                          } else {
                            setMergerAndAcquisitionEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="M & A Activities"
                  name="mergers_and_acquisition_Activities"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={regulatoryLegalEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={regulatoryLegalEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setRegulatoryLegalEnabled(true);
                          } else {
                            setRegulatoryLegalEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Regulatory & Legal"
                  name="regulatory_and_legal"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={fundingActivityEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={fundingActivityEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setFundingActivityEnabled(true);
                          } else {
                            setFundingActivityEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Funding Activity"
                  name="funding_activity"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={businessExpansionEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={businessExpansionEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setBusinessExpansionEnabled(true);
                          } else {
                            setBusinessExpansionEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Business Expansion"
                  name="business_expansion"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={operationalChallengesEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={operationalChallengesEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setOperationalChallengesEnabled(true);
                          } else {
                            setOperationalChallengesEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Operational Challenges"
                  name="operational_challenges"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={partnershipsAndAlliancesEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={partnershipsAndAlliancesEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setPartnershipsAndAlliancesEnabled(true);
                          } else {
                            
                            setPartnershipsAndAlliancesEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="PartnerShips & Alliances"
                  name="partnerships_and_alliances"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={eventsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                     checked={eventsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setEventsEnabled(true);
                          } else {
                            setEventsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Events"
                  name="events"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={newOfferingsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={newOfferingsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setNewOfferingsEnabled(true);
                          } else {
                            setNewOfferingsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="New Offerings"
                  name="new_offerings"
                />
              </div>

              
              {/* <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                      tabIndex={-1}
                      onClick={() => handleToggle(2)}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Events"
                />
              </div> */}

             
              </GridItem>

         

              <GridItem  xs={12} sm={12} md={3}>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={managementChangesEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={managementChangesEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setManagementChangesEnabled(true);
                          } else {
                            setManagementChangesEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Management Changes"
                  name="management_changes"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={awardsAndRecognitionsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={awardsAndRecognitionsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setAwardsAndRecognitionsEnabled(true);
                          } else {
                            setAwardsAndRecognitionsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Awards & Recognition"
                  name="awards_and_recognition"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={procurementAndSalesEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={procurementAndSalesEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setProcurementAndSalesEnabled(true);
                          } else {
                            setProcurementAndSalesEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Procurement & Sales"
                  name="procurement_and_sales"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={costCuttingEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={costCuttingEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setCostCuttingEnabled(true);
                          } else {
                            setCostCuttingEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Cost Cutting"
                  name="cost_cutting"
                />
              </div>
              
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={businessClosureEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={businessClosureEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setBusinessClosureEnabled(true);
                          } else {
                            setBusinessClosureEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Business Closure"
                  name="businessClosureEnabled"
                />
              </div>
              
              
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={auditRiskEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={auditRiskEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setAuditRiskEnabled(true);
                          } else {
                            setAuditRiskEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Audit Risk"
                  name="audit_risk"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={layOffsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={layOffsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setLayOffsEnabled(true);
                          } else {
                            setLayOffsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="LayOffs"
                  name="layoffs"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={roboticsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={roboticsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setRoboticsEnabled(true);
                          } else {
                            setRoboticsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Robotics"
                  name="robotics"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={iotEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={iotEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setIotEnabled(true);
                          } else {
                            
                            setIotEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="IoT"
                  name="iot"
                />
              </div>

           
              </GridItem>
              <GridItem  xs={12} sm={12} md={3}>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={cyberSecurityEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={cyberSecurityEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setCyberSecurityEnabled(true);
                          } else {
                            setCyberSecurityEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Cyber Security"
                  name="cyber_security"
                />
              </div>
                <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={blockChainEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={blockChainEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setBlockChainEnabled(true);
                          } else {
                            
                            setBlockChainEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="BlockChain"
                  name="block_chain"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={artificialIntelligenceEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={artificialIntelligenceEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setArtificialIntelligenceEnabled(true);
                          } else {
                            setArtificialIntelligenceEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Artificial Intelligence"
                  name="artificial_intelligence"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={advancedManufacturingEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={advancedManufacturingEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setAdvancedManufacturingEnabled(true);
                          } else {
                            setAdvancedManufacturingEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Advanced Manufacturing"
                  name="advanced_manufacturing"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={bigDataOrAnalyticsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={bigDataOrAnalyticsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setBigDataOrAnalyticsEnabled(true);
                          } else {
                            setBigDataOrAnalyticsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Big Data/Analytics"
                  name="big_data_or_analytics"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={emergingTechEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={emergingTechEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setEmergingTechEnabled(true);
                          } else {
                            
                            setEmergingTechEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="EmergingTech"
                  name="emerging_tech"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={divestitureEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={divestitureEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setDivestitureEnabled(true);
                          } else {
                            
                            setDivestitureEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Divestiture"
                  name="divestiture"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={capitalInvestmentEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={capitalInvestmentEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setCapitalInvestmentEnabled(true);
                          } else {
                            setCapitalInvestmentEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Capital Investment"
                  name="capital_investment"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={corporateRestructuringEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={corporateRestructuringEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setCorporateRestructuringEnabled(true);
                          } else {
                            setCorporateRestructuringEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Corporate Restructuring"
                  name="corporate_restructuring"
                />
              </div>
              </GridItem>
    <GridItem  xs={12} sm={12} md={3}>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={negativeNewsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={negativeNewsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            setNegativeNewsEnabled(true);
                          } else {
                            setNegativeNewsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Negative News"
                  name="negative_news"
                />
              </div>


              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={hiringEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
                      checked={hiringEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setHiringEnabled(true);
                          } else {
                            
                            setHiringEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Hiring"
                  name="hiring"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={financialResultsEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={financialResultsEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setFinancialResultsEnabled(true);
                          } else {
                            setFinancialResultsEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Financial Results"
                  name="financial_results"
                />
              </div>

              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={defaultFinanceEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={defaultFinanceEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setDefaultFinanceEnabled(true);
                          } else {
                            setDefaultFinanceEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Default(Finance)"
                  name="default_finance"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={arOrVrEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={arOrVrEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setArOrVrEnabled(true);
                          } else {
                            setArOrVrEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="AR/VR"
                  name="ar_or_vr"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={capitalRefinanceOrRestructuringEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={capitalRefinanceOrRestructuringEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            setCapitalRefinanceOrRestructuringEnabled(true);
                          } else {
                            setCapitalRefinanceOrRestructuringEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Capital Refinance/Restructuring"
                  name="capital_refinance_or_restructuring"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={demergerOrSpinOffEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={demergerOrSpinOffEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            
                            setDemergerOrSpinOffEnabled(true);
                          } else {
                            
                            setDemergerOrSpinOffEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Demerger/SpinOff"
                  name="demerger_or_spinoff"
                />
              </div>
              <div className={classes.checkboxAndRadio}>
                <FormControlLabel
                  control={
                    <Checkbox
                    value={financialEnabled}
                      tabIndex={-1}
                      onClick={handleToggle}
              checked={financialEnabled}
                      defaultChecked={classes.uncheckedIcon}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot
                      }}
                      inputProps={{
                        onChange: event => {
                          if (event.target.checked) {
                            
                            
                            
                            setFinancialEnabled(true);
                          } else {
                            
                            setFinancialEnabled(false);
                          }
                          
                        },
                        
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                    root: classes.labelRoot
                  }}
                  label="Others"
                  name="financials"
                />
              </div>
              </GridItem>

              </GridContainer>
<br></br>
              <GridItem xs={12} sm={12} md={12}>
      
      <div className={classes.buttonGroup}>

        <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}} >
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button>
        {/* <Button color="info" size="sm" className={classes.firstButton} onClick={submitForm} style={{fontSize:16, fontWeight: "bold"}} disabled={showButtonDisable || !emailId || !mobileNumber}>
        <SaveIcon/>  &nbsp;&nbsp;Save  
        </Button> */}
        {/* <Button color="info" size="sm" className={classes.middleButton}>
          Middle
        </Button>
        <Button color="info" size="sm" className={classes.lastButton}>
          Right
        </Button> */}
      </div>

 
</GridItem>


<div>
<SweetAlert
success
show={alert1}

style={{ display: "block", marginTop: "-100px"}}
 onConfirm={() => hideAlert1()}
 confirmBtnText="&nbsp;&nbsp;&nbsp;OK&nbsp;&nbsp;&nbsp;"
 confirmBtnStyle={{color: "#000000" , fontSize: "17px", fontWeight:"bold", paddingTop: "10px", paddingLeft: "10px", paddingBottom: "10px", paddingRight: "10px", backgroundColor: infoColor[1]}}
// onCancel={() => hideAlert()}
confirmBtnCssClass={
classes.button + " " + classes.error

}
>
Saved Successfully!
</SweetAlert>
</div>
                    

              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      </form>
    </div>
  );
}else{
  return(
    <div align="center">
    <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={500}
        width={100}
        timeout={3000} //3 secs
      /></div>
      )
    }
  }
AssetManagerNotificationSettings.propTypes = {

  // notificationSettingsById: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     userId: PropTypes.string,
  //   }),
  // ).isRequired,


  loading: PropTypes.bool.isRequired,
  tmSaveNotificationSettingsRequest: PropTypes.func.isRequired,
  // tmFundListRequest: PropTypes.func.isRequired,
  tmNotificationSettingsByIdRequest: PropTypes.func.isRequired,
  tmEditNotificationSettingsRequest:PropTypes.func.isRequired,
  // tmAssetManagerListRequest:PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.loggedUser,
  loading: state.tenantmonitoring.loading,
  notificationSettingsById: state.tenantmonitoring.notificationSettingsById,
  editNotificationSettings:state.tenantmonitoring.editNotificationSettings,
  //assetManagerList: state.tenantmonitoring.assetManagerList
});              

const mapDispatchToProps = dispatch => bindActionCreators(
  {

    ...TMActions,
  },
  dispatch,
);
export default  connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssetManagerNotificationSettings);